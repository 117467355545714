import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Logo from '../img/logo.svg';
import firefly from "../js/util/firefly";
import $ from 'jquery';

const backgroundImages = [
    require('../img/background00.png'),
    require('../img/background01.png'),
    require('../img/background02.png'),
    require('../img/background03.png'),
    require('../img/background04.png')
];

function Main() {

    const [mcPlayerCount, setMcPlayerCount] = useState(null);
    const [trPlayerCount, setTrPlayerCount] = useState(null);
    const [ip] = useState("294.moe");
    const [backgroundImage, setBackgroundImage] = useState("");

    // 파티클 이펙트
    useEffect(() => {
        firefly();

        return () => {
            if ($.firefly && $.firefly.pause) {
                $.firefly.pause();
            }
        };
    }, []);

    // 배경이미지 랜덤 설정
    useEffect(() => {
        const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
        setBackgroundImage(randomImage);
    }, []);

    // 우클릭 차단
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
        }
    }, []);

    // 마인크래프트 서버 접속자 수
    useEffect(() => {
        const updatePlayerCount = async () => {
            try {
                const response = await fetch(`https://api.mcsrvstat.us/3/${ip}`);
                const result = await response.json();
                if (result.players) {
                    setMcPlayerCount(result.players.online);
                } else {
                    setMcPlayerCount("오프라인");
                }
            } catch (error) {
                setMcPlayerCount("실패");
            }
        };

        updatePlayerCount();
        const interval = setInterval(updatePlayerCount, 60000);
        return () => clearInterval(interval);
    }, [ip]);

    // 테라리아 서버 접속자 수
    useEffect(() => {
        const updateTerrariaInfo = async () => {
            try {
                const response = await axios.get(`http://localhost:5050/api/getInfo?token=${process.env.ETW_API_TOKEN}`);
                const { trPlayerCount } = response.data;
                setTrPlayerCount(trPlayerCount);
            } catch (error) {
                setTrPlayerCount("?");
                console.error("Terraria info fetch error:", error);
            }
        };

        updateTerrariaInfo();
        const interval = setInterval(updateTerrariaInfo, 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Helmet>
              <title>294 Multiserver: Operation Enjoy</title>
              <meta name="description" content="294의 지인제 멀티플레이 서버" />
              <meta property="og:title" content="Multiserver: Operation Enjoy" />
              <meta property="og:site_name" content="294 멀티서버 프로젝트" />
              <meta property="og:description" content="294의 지인제 멀티서버 프로젝트" />
            </Helmet>
            
            {/* <div className="background"
                style={{
                  backgroundImage: `linear-gradient(#203940dd, #203940dd), url(${backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
            > */}
            <div className="background">
                <div className="main-wrapper">

                    <div className="logo-wrapper">
                        <Link to="https://294.ink" className="logo">
                            <img src={Logo} alt="294 Logo" />
                        </Link>
                    </div>

                    <div className="top-wrapper">
                        <h1 className="main-title">294 Multiserver : Operation Enjoy</h1>
                        <h3 className="main-subtitle">지인제 멀티플레이 게임 서버 프로젝트</h3>
                    </div>

                    <div className="server-wrapper">
                        <div className="server-item">
                            <h1 className="title">294 Minecraft</h1>
                            <h3 className="subtitle">시즌3: All The Mods 10</h3>
                            <p className="playercount">
                                {mcPlayerCount !== null ? ( 
                                    <>현재 <span className="sip">{mcPlayerCount}</span> 명 접속중!</>
                                ) : "접속 정보 로드 중..."}
                            </p>
                        </div>
                        
                        <div className="server-item">
                            <h1 className="title">294 Terraria</h1>
                            <h3 className="subtitle">시즌1: Wild Life</h3>
                            <p className="playercount">
                                {trPlayerCount !== null ? (
                                    <>현재 <span className="sip">{trPlayerCount}</span> 명 접속중!</>
                                ) : "접속 정보 로드 중..."}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;


{/* <div className="items">
    <a href="https://294.moe/map" className="item">
        <i className="far fa-map"></i>
        <span>
            <p>웹 지도</p>
        </span>
    </a>
    <Link to="/info" className="item">
        <i className="far fa-book"></i>
        <span>
            <p>서버 가이드</p>
        </span>
    </Link>
    <Link to="https://www.curseforge.com/minecraft/modpacks/all-the-mods-9-to-the-sky/files" className="item">
        <i className="far fa-download"></i>
        <span>
            <p>모드팩 설치</p>
        </span>
    </Link>
</div> */}